@import 'styles/_variables.scss';

.step1-thanks__main {
    max-width: 68.4rem;
    text-align: center;

    @media screen and (min-width: $breakpoint-mobile) {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding-top: $x-large-spacing 0;
    }

    h1 {
        font-size: 26px;
        font-weight: $extraBold;
        line-height: 34px;

        @media screen and (min-width: $breakpoint-mobile) {
            font-size: 32px;
        }
    }

    h2 {
        font-size: 16px;
        line-height: 26px;
        margin: $small-spacing auto;
    }
}
