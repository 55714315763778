@import "styles/_variables.scss";

.main-step2__cards-container {
    display: grid;
    // limits the height on big screens
    grid-auto-rows: min-content;
    min-height: 100%;
    row-gap: 1.6rem;
    width: 100%;

    @media screen and (min-width: $breakpoint-mobile) {
        column-gap: 1.6rem;
        grid-template-columns: repeat(3, 1fr);
    }
}

.Toastify__toast-container {
    @media screen and (min-width: $breakpoint-mobile) {
        margin-bottom: 5rem;
        // center the toast on the main container
        margin-left: calc(#{$width-panel} / 2);
    }
}
