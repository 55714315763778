@import 'styles/_variables';

.toast__container {
    background-color: $greyCloudy;
    color: $black;
    display: flex;
    align-items: flex-start;
}

.toast__leading {
    margin-right: 1rem;
}

.toast__content {
    > h4 {
        font-weight: $bold;
    }
}
