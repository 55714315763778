@import 'styles/_variables';

.progressBar {
    height: 8px;
    background-color: $greyCloudy;
    border-radius: $borderRadius;
    margin-bottom: 1.6rem;

    &__content {
        height: 100%;
        background-color: $primary;
        border-radius: $borderRadius;

        &--black {
            background-color: $black;
        }
    }
}
