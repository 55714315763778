@import 'styles/_variables';

.button-icon {
    align-items: center;
    color: $greyGraphiteLight;
    display: flex;
    height: unset;
    text-decoration: none;
    width: unset;

    > span {
        font-size: 1.4rem;
        line-height: 2.2rem;
        padding-left: 1rem;
    }
}
