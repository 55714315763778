@import 'styles/_variables';

strong,
.strong {
    font-weight: $bold;
}

.center {
    text-align: center;
}

.base-margin {
    margin-top: $base-spacing;
}

.small-margin {
    margin-top: $small-spacing;
}

.margin-top--large {
    margin-top: $large-spacing;
}

.block {
    display: block;
}

.margin-bottom-4 {
    margin-bottom: 0.4rem;
}

.flex-row {
    display: flex;
    width: 100%;
}

.flex-col {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.margin-right-16 {
    margin-right: 1.6rem;
}

.small-text {
    font-size: 1rem;
}

.margin-right--x-small {
    margin-right: $x-small-spacing;
}

.no-padding {
    padding: 0 !important;
}
