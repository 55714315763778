@import 'styles/_variables';

.error-page__container {
    display: flex;
    flex-direction: column;
    height: inherit;
}

.error-page__header {
    padding: 3.6rem;

    @media screen and (max-width: $breakpoint-mobile) {
        padding: 2.4rem 2rem;
    }
}

.error-page-main {
    align-items: center;
    display: flex;
    justify-content: center;

    .error-page-main__content {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 2rem 2rem 0 2rem;

        @media screen and (min-width: $breakpoint-mobile) {
            border-radius: $borderRadius;
            border: 2px solid $black;
            justify-content: center;
            margin-top: 7rem;
            min-height: 420px;
            min-width: 964px;
            padding: 6rem 6.4rem;
            position: relative;
        }

        h1 {
            color: $black;
            font-size: 3.2rem;
            font-weight: 800;
            margin-bottom: 1.6rem;
        }

        span {
            margin-bottom: 1.6rem;
            max-width: 38rem;
        }

        img {
            margin-top: 2rem;

            @media screen and (min-width: $breakpoint-mobile) {
                margin-top: 0;
                position: absolute;
                right: 6.4rem;
            }
        }
    }
}
