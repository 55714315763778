@import 'styles/_variables';

.admin {
    text-align: left;

    .main-card {
        margin-top: $large-spacing;

        @media screen and (min-width: $breakpoint-mobile) {
            border-radius: $borderRadius--large;
        }
    }

    h1,
    h2 {
        font-weight: $extraBold;
        margin-bottom: $large-spacing;
    }

    h1 {
        font-size: 3.2rem;
    }

    ul {
        padding-left: $base-spacing;
    }

    &__back-button {
        color: $white !important;
        min-height: unset;
        padding: 0 !important;
    }

    &__button-container {
        margin: 2rem 0;
        text-align: center;
    }

    &__content {
        text-align: left;
    }

    &__list {
        font-size: 1.6rem;
        list-style: disc;
        margin-bottom: $large-spacing;
    }

    &__title {
        font-size: 3rem;
    }
}
