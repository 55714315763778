@import "styles/_variables.scss";

.main-dashboard {
    &__container {
        h1 {
            font-size: 2.6rem;
            font-weight: $extraBold;
            line-height: 3.4rem;
            margin-bottom: 1.6rem;

            @media screen and (max-width: $breakpoint-mobile) {
                text-align: center;
            }
        }

        > h4 {
            font-size: 1.8rem;
            font-weight: $extraBold;
            line-height: 2.6rem;
            margin-bottom: 3.6rem;

            @media screen and (max-width: $breakpoint-mobile) {
                margin-bottom: 1.6rem;
                text-align: center;
            }
        }
    }

    &__directions {
        @media screen and (max-width: $breakpoint-mobile) {
            display: block;
            text-align: center;
        }
    }

    &__cards-container {
        display: grid;
        // limits the height on big screens
        grid-auto-rows: min-content;
        margin: 3rem 0 5rem;
        min-height: 100%;
        row-gap: 1.6rem;
        width: 100%;

        @media screen and (min-width: $breakpoint-mobile) {
            column-gap: 1.6rem;
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
