@import 'styles/_variables';

.similar-job-card {
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: $white;
    border-radius: $borderRadius;
    border: 1px solid $grey;
    min-height: 13.6rem;
    padding: 1.5rem;
    text-align: left;

    h4 {
        font-weight: $extraBold;
        font-size: 1.8rem;
        line-height: 2.7rem;
        margin-bottom: 0.5rem;
    }

    &__description {
        margin-bottom: 1.6rem;
    }

    &:hover {
        box-shadow: $boxShadow;
        cursor: pointer;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        font-size: 1.6rem;
    }
}
