@import 'styles/_variables.scss';

.checkbox {
    display: flex;
    align-items: baseline;
    margin-bottom: 1rem;
    line-height: 2.2rem;
    text-align: left;

    input {
        margin-right: 1.2rem;
        border: 1px solid $grey;
        transform: scale(1.2);
        flex: 0 0 1.6rem; // fix iPhone style
    }

    label {
        color: $black;
        font-size: 1.4rem;

        a {
            color: $black;
        }
    }

    &--error {
        padding: 1rem;
        border: solid 1px $pinkRed;
        background-color: #fdf4f7;
        color: $pinkRed;
    }
}
