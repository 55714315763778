@import "styles/_variables.scss";

.category-selection {
    .main-steps__footer {
        align-items: center;
        display: flex;
        flex-direction: column;

        .button--block-mobile {
            width: 100%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        text-align: left;

        @media screen and (min-width: $breakpoint-mobile) {
            max-width: 44rem;
        }

        h4 {
            color: $greyDark;
            margin-bottom: 1.5rem;
        }
    }
}
