@import 'styles/_variables';

.card-fun-fact {
    background-color: $backgroundColor;
    border-radius: $borderRadius;
    margin-top: 3.6rem;
    padding: 1.6rem;

    img {
        margin-right: 0.8rem;
        margin-bottom: 0.8rem;
    }
}
