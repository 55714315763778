@import 'styles/_variables';

.card-similar-jobs {
    margin: 6rem auto;
    max-width: 78.5rem;

    @media screen and (max-width: $breakpoint-mobile) {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
    }

    &__card {
        display: grid;
        grid-auto-rows: min-content;
        margin-bottom: 3rem;
        min-height: 100%;
        row-gap: 1.6rem;
        width: 100%;

        @media screen and (min-width: $breakpoint-mobile) {
            column-gap: 1.6rem;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    & h2 {
        font-size: 1.8rem;
        color: $grey;
        margin-bottom: 3rem;
        font-weight: $extraBold;
        line-height: 2.6rem;
    }
}
