@import 'styles/_variables';

.card-job-offers {
    display: flex;
    flex-direction: column;
    margin: 5.8rem 0;
    background-color: $greyCloudy;
    max-width: 100%;
    padding: 4rem 0;
    width: 100%;

    &__title {
        & img {
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 1rem;
        }

        & span {
            font-size: 1.8rem;
            color: $black;
            line-height: 2.6rem;
        }
    }

    &__cards {
        overflow-x: auto;
        display: inline-flex;
        column-gap: 2.7rem;
        padding: 3.6rem 0 1rem;
        max-width: calc(100% - 13.5rem);
        margin: auto;

        @media screen and (max-width: $breakpoint-mobile) {
            padding: 2.3rem 1rem 1rem;
            max-width: 100%;
        }
    }
}
