@import 'styles/_variables';

.home-card {
    background-color: $greyLighter;
    border-radius: $borderRadius;
    display: flex;
    margin: 1.6rem 0;
    padding: 2.8rem;
    width: 100%;

    @media screen and (max-width: $breakpoint-desktop) {
        flex-direction: row;
    }

    @media screen and (min-width: $breakpoint-desktop) {
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
}

.home-card__title {
    margin-bottom: 1rem;
    font-size: 2.2rem;
    font-weight: $extraBold;
    line-height: 2.6rem;
}

.home-card__img {
    height: 7.5rem;
    width: auto;

    @media screen and (max-width: $breakpoint-desktop) {
        margin-right: 1.6rem;
    }

    @media screen and (min-width: $breakpoint-desktop) {
        margin-bottom: 1.6rem;
    }
}
