@import 'styles/_variables';

.main-with-back-button {
    &__wrapper {
        height: 100%;
        overflow-x: auto;
        overflow-y: auto;
        position: relative;
        width: 100%;
        z-index: 10;

        .main {
            margin: auto;
            max-width: $max-width-main;
            padding: 3rem 2.4rem;

            &--large {
                max-width: $max-width-main-large;
            }
        }
    }

    &__title {
        font-size: 3.2rem !important;
        margin-bottom: $base-spacing;
        text-align: left;
    }

    &__button-container {
        margin-left: 2rem;
        margin-top: $small-spacing;

        @media screen and (min-width: $breakpoint-mobile) {
            margin-left: $base-spacing;
            margin-top: $base-spacing;
        }
    }
}
