@import 'styles/_variables';

.feedback {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 3.5rem;

    &__button {
        border-radius: 100rem !important;
        bottom: 0.5rem;
        display: block;
        position: fixed;
        right: 3rem;
    }

    &__form {
        align-items: center;
        bottom: 0;
        display: flex;
        left: 30rem;
        margin: auto;
        max-width: 60rem;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 200;

        @media screen and (max-width: $breakpoint-mobile) {
            left: 0;
            top: 7.4rem;
            width: calc(100% - 1rem);
        }

        form {
            max-height: calc(100vh - 28rem);
            overflow: auto;
        }

        &__content {
            background-color: $white;
            border-radius: $borderRadius;
            color: inherit;
            padding: 3.6rem 3.6rem;
            position: relative;
            width: 100%;
        }

        &__button {
            display: flex;
            justify-content: flex-end;

            @media screen and (max-width: $breakpoint-mobile) {
                align-items: baseline;
                flex-wrap: wrap;
                justify-content: center;

                button:last-child {
                    margin-top: $small-spacing;
                }
            }

            button:first-child {
                margin-right: $small-spacing;
            }
        }

        .label {
            font-size: 14px;
            text-align: left;
        }

        .choicefield__wrapper {
            @media screen and (min-width: $breakpoint-tablet) {
                flex-wrap: wrap;
            }
        }

        .form-group {
            margin-bottom: 1.6rem;

            & > select {
                background-color: $white;
                border-radius: 4px;
                border: 1px solid $grey;
                box-sizing: border-box;
                width: 100%;
            }
        }
    }

    &__header {
        display: flex;

        &__title {
            font-size: 2.6rem;
            font-weight: $extraBold;
            line-height: 3.4rem;
            text-align: left;
            width: 100%;
        }

        &__close {
            cursor: pointer;
            height: 100%;
            margin-left: 0.5rem;
        }
    }

    &__thanks {
        text-align: center;

        .feedback__title {
            margin-bottom: $x-small-spacing;
            margin-top: $base-spacing;
            text-align: center;
        }
    }
}
