@import 'styles/_variables.scss';

.step1__container {
    .stopExploration {
        display: block;
        padding: 2.4rem;
        z-index: 10;
    }

    .main-steps {
        &__content {
            overflow-x: hidden;
        }

        &__container {
            @media screen and (max-width: $breakpoint-mobile) {
                height: unset;
            }
        }
    }

    .detailed-card__wrapper {
        margin-top: 0;
    }
}
