@import 'styles/_variables';

.delete-account {
    &__buttons {
        text-align: right;

        button:first-child {
            margin-right: $small-spacing;
        }
    }
}
