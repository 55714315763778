@import 'styles/_variables';

.card-job__card-container {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.card-job__card {
    flex: 1;
}

.card-job__domains {
    column-gap: 0.8rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.8rem;
}

.card-job__title {
    font-size: 2.2rem;
    font-weight: $bold;
    line-height: 2.8rem;
    margin-top: 2.4rem;
    overflow-wrap: break-word;

    &::first-letter {
        text-transform: capitalize;
    }

    @media screen and (min-width: $breakpoint-mobile) {
        font-size: 3.2rem;
        line-height: 4.2rem;
    }
}

.card-job__divider {
    border-top-color: $greyCloudy;
    margin: 1.6rem 0;

    &--black {
        border-top-color: $black;
    }
}

.card-job__info-container {
    text-align: left;

    button {
        cursor: pointer;
    }
}

.button-close {
    align-items: center;
    background: none;
    border: none;
    color: $white;
    display: flex;
    float: right;
    padding: 0;

    img {
        margin: 0 !important;
    }
}
