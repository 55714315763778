@import 'styles/_variables.scss';

.step__onboarding__main {
    padding-bottom: 0;

    h1 {
        font-size: 2.2rem;
        font-weight: $extraBold;
        line-height: 2.8rem;
        margin-bottom: $small-spacing;
        text-align: center;

        @media screen and (min-width: $breakpoint-mobile) {
            font-size: 3.2rem;
        }
    }

    h2 {
        color: $greyGraphiteLight;
        margin-bottom: $x-small-spacing;
        text-align: center;
    }

    img {
        margin-bottom: $large-spacing;

        @media screen and (min-width: $breakpoint-mobile) {
            margin-bottom: $small-spacing;
        }
    }

    .button {
        @media screen and (min-width: $breakpoint-mobile) {
            margin: auto;
            width: fit-content;
        }
    }
}
