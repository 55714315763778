@import 'styles/_variables';

.step2__action-bar__wrapper {
    display: flex;
    margin: 2.4rem 0;

    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        border: 1px solid $black;
        border-radius: $borderRadius;
        box-shadow: $boxShadow;
        height: 5rem;
        width: auto;
        padding: 0 1.6rem;

        &--selected {
            background-color: $primary;
            border-color: $primary;
        }

        img {
            margin-right: 1.2rem;
            width: 2rem;
        }
    }
}
