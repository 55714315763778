@import "styles/_variables";

.home-content {
    &__container {
        align-items: center;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
    }

    &__cards {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 0 2rem 9.6rem;
        max-width: 116.4rem;

        &__text {
            margin: 9.6rem 2rem;
            max-width: 76.4rem;
            text-align: center;

            &__title {
                line-height: 4.2rem;
                font-size: 3.2rem;
                font-weight: $extraBold;
                margin-bottom: 0.8rem;
            }

            &__description {
                margin-bottom: 3.6rem;
            }
        }

        &__wrapper {
            display: grid;

            @media screen and (max-width: $breakpoint-desktop) {
                row-gap: 2.4rem;
                grid-template-rows: auto auto auto;
            }

            @media screen and (min-width: $breakpoint-desktop) {
                column-gap: 3.6rem;
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }

    &__what-to-expect {
        display: flex;
        margin: 0 2rem 6.4rem;
        max-width: 116.4rem;

        @media screen and (max-width: $breakpoint-desktop) {
            align-items: center;
            flex-direction: column;
            text-align: center;
        }

        @media screen and (min-width: $breakpoint-desktop) {
            flex-direction: row;
            justify-content: space-between;
        }

        &__description-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;

            @media screen and (max-width: $breakpoint-desktop) {
                margin-bottom: 3.6rem;
                max-width: 76.4rem;
            }

            @media screen and (min-width: $breakpoint-desktop) {
                margin-right: 13.6rem;
                max-width: 46.4rem;
            }
        }

        &__illustration {
            max-width: 56.4rem;
            width: 100%;
        }
    }

    &__your-way {
        border: 1px solid $greyCloudy;
        border-radius: $borderRadius;
        display: grid;
        margin: 0 2rem 9.6rem;
        padding: 2rem 4rem;

        @media screen and (max-width: $breakpoint-mobile) {
            justify-items: center;
            max-width: 56.4rem;
            row-gap: 2.9rem;
        }

        @media screen and (min-width: $breakpoint-mobile) {
            column-gap: 5.6rem;
            grid-template-columns: auto auto;
            width: 76.4rem;
        }

        button {
            margin-top: 1.6rem;

            @media screen and (max-width: $breakpoint-mobile) {
                width: 100%;
            }

            @media screen and (min-width: $breakpoint-mobile) {
                width: fit-content;
            }
        }

        a {
            color: $black;
        }

        p {
            margin: 0;
        }

        ul {
            list-style: disc;
            padding-left: 3rem;
        }

        &__title {
            font-size: 2.2rem;
            font-weight: $extraBold;
            line-height: 2.8rem;
            margin-bottom: 0.8rem;
        }
    }

    &__banner {
        background-color: $primary;
        display: grid;
        justify-items: center;
        padding: 6.4rem 2rem;
        text-align: center;
        width: 100%;

        @media screen and (max-width: $breakpoint-mobile) {
            margin-bottom: 6.4rem;
        }

        @media screen and (min-width: $breakpoint-mobile) {
            margin-bottom: 9.6rem;
        }

        &__title {
            font-size: 4.2rem;
            font-weight: $extraBold;
            line-height: 4.2rem;
            margin-bottom: 3.6rem;
            max-width: 76.4rem;
        }
    }

    &__mission {
        display: grid;
        margin: 0 2rem -0.2rem;

        @media screen and (max-width: $breakpoint-mobile) {
            justify-items: center;
            max-width: 76.4rem;
            text-align: center;
        }

        @media screen and (min-width: $breakpoint-mobile) {
            align-items: center;
            grid-template-columns: 1fr 1fr;
            justify-items: center;
        }

        &__description {
            a {
                color: $black;
            }

            @media screen and (max-width: $breakpoint-mobile) {
                margin-bottom: 3.6rem;
            }

            @media screen and (min-width: $breakpoint-mobile) {
                max-width: 56.4rem;
            }
        }

        &__logos {
            margin-top: 3.6rem;
        }

        &__logo:not(:last-child) {
            margin-right: 3.6rem;
        }
    }
}
