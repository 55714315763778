@import 'styles/_variables';

.step1__action-bar__wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2.4rem;

    button {
        align-items: center;
        background-color: $white;
        border-radius: 50%;
        box-shadow: $boxShadow;
        display: flex;
        height: 5rem;
        justify-content: center;
        width: 5rem;

        &:first-child {
            margin-right: 3.6rem;
        }
    }
}
