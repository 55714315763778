@import 'styles/_variables.scss';

.label {
    display: block;
    margin: 0 0 1rem;
    font-weight: bold;
}

.form-group {
    margin-bottom: 3.6rem;
}

input {
    font-family: 'Mulish', sans-serif;
}

select {
    max-width: 100%;
    padding: 1rem 0.5rem;
    font-family: 'Mulish', sans-serif;
    border: 1px solid $grey;
}
