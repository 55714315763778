@import 'styles/_variables';

.home-sso-banner {
    text-align: left;

    a {
        color: $greyDark2;
        font-weight: $bold;
    }
}
