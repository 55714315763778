@import 'react-toastify/dist/ReactToastify.css';
@import 'styles/_variables';

.toast--success {
    background-color: $greyCloudy;
    color: $black;
}

.Toastify__close-button--success {
    color: $black;
}

.Toastify__toast-container {
    @media screen and (min-width: $breakpoint-mobile) {
        width: 55rem;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        // margin to have the toast under the header menu
        margin-top: 7.4rem;
    }
}
