@import 'styles/_variables';

.home-footer {
    background-color: $black;
    color: $white;
    margin-top: auto;
    padding: 0 8.5% 0;
    position: relative;

    @media screen and (max-width: $breakpoint-mobile) {
        border: none;
        padding: 3.6rem;
        text-align: center;
    }
}

.home-footer__language-switcher {
    align-items: center;
    display: flex;
    flex-direction: column;

    .language-switcher {
        min-width: 15rem;
    }
}

.home-footer__language-switcher > *:not(:last-child) {
    margin-bottom: 30px;
}

.home-footer__links {
    align-items: center;
    display: flex;
    font-size: 1.3rem;
    justify-content: space-between;

    @media screen and (max-width: $breakpoint-mobile) {
        align-items: center;
        flex-direction: column;
        font-size: 1.4rem;
    }
}

.home-footer__link {
    color: $white;
    font-weight: normal;
    margin-right: 2rem;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        margin-bottom: 2.4rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.home-footer__links--left {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $breakpoint-mobile) {
        align-items: center;
        flex-direction: column;
    }
}

.home-footer__divider {
    border-color: rgb(189, 189, 192, 0.4); // #grey with 0.4 opacity
    margin: 3.6rem 0;
    width: 100%;
}

.home-footer__copyright {
    margin: 3.6rem;

    @media screen and (max-width: $breakpoint-mobile) {
        margin: 0 3.6rem 1rem;
    }
}
