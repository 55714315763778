@import 'styles/_variables';

.job-dashboard__container {
    border-radius: $borderRadius;
    border: 1px solid $grey;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    text-align: left;

    h4 {
        font-weight: $extraBold;
        font-size: 1.8rem;
        line-height: 2.7rem;
        margin-bottom: 1.6rem;
    }
}

.job-dashboard__detail {
    align-items: center;
    display: flex;
    margin-bottom: 1.6rem;
}

.job-dashboard__icon {
    margin-right: 0.8rem;
    height: 1.6rem;
    right: 1.6rem;
}

.job-dashboard__link {
    border-radius: $borderRadius;
    border: solid 1px $black;
    font-size: 1.4rem;
    text-decoration: none;
    color: $black;
    padding: 0.7rem 1.6rem;
    margin: auto auto 0 0;
}

.bar {
    height: 8px;
    background-color: $greyCloudy;
    border-radius: $borderRadius;
    margin-bottom: 1.6rem;

    &__content {
        height: 100%;
        background-color: $primary;
        border-radius: $borderRadius;
    }
}
