@import 'styles/_variables';

.description-admin {
    p {
        font-size: 14px;
    }

    img {
        margin-left: -$base-spacing;
        margin-top: $base-spacing;
    }
}
