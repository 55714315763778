@import 'styles/_variables';

.main-card {
    background-color: $white;
    border-radius: $borderRadius--x-large;
    margin: 0 auto;
    padding: 3.6rem;
    position: relative; // need for absolute child
    width: 100%;

    &.main-card--no-padding {
        @media screen and (min-width: $breakpoint-mobile) {
            padding: 0;
        }
    }

    @media screen and (max-width: $breakpoint-mobile) {
        border-radius: 0;
        margin-bottom: -$large-spacing;
        margin-left: -20px;
        min-height: fit-content;
        padding: 2.4rem 2rem;
        width: calc(100% + 40px);
    }
}

.main-card--small {
    max-width: 536px;
}

.main-card__title {
    margin-bottom: 4.8rem;

    @media screen and (max-width: $breakpoint-mobile) {
        margin-bottom: 3.6rem;
    }
}

.main-card__header {
    display: flex;
    margin: 0 auto;
    max-width: 864px;
    width: 100%;

    @media screen and (max-width: $breakpoint-mobile) {
        max-width: unset;
        padding: 0;
    }
}
