@import 'styles/_variables';

.stepList {
    &__step {
        border-left: 2px solid $blue;
        padding: $small-spacing;

        p {
            font-size: 1.4rem;
        }

        &--hidden {
            border-color: $greyGraphiteLight;
            color: $greyGraphiteLight;
        }
    }
}
