@import 'styles/_variables';

.main-steps {
    height: 100%;

    @media screen and (max-width: $breakpoint-mobile) {
        padding: $small-spacing 20px;
    }

    &__with-back-button {
        @media screen and (min-width: $breakpoint-mobile) {
            padding-top: $large-spacing;
        }
    }

    &__back-button {
        margin-bottom: $small-spacing;
        width: 100%;

        @media screen and (min-width: $breakpoint-mobile) {
            left: 0;
            margin-bottom: 0;
            margin-left: $base-spacing;
            position: absolute;
            top: $base-spacing;
            width: unset;
        }
    }

    &__container {
        display: grid;
        grid-row-gap: 1rem;
        grid-template-rows: max-content auto max-content;
        height: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
        z-index: 1;
    }

    &__content {
        overflow-y: auto;
    }

    &__footer {
        margin-top: 1rem;
    }

    &__header {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 2.6rem;
        min-height: fit-content;
        text-align: center;
        width: 100%;

        @media screen and (max-width: $breakpoint-mobile) {
            .title {
                font-size: 2.2rem;
                line-height: 2.8rem;
            }
        }
    }

    &__subheader {
        color: $greyDark;
        margin-bottom: $small-spacing;
    }

    &__subtitle {
        margin-top: 0.8rem;
    }
}
