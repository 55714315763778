/* COLORS */
$black: #000;
$blue: #00cddd;
$blueDark: desaturate($blue, 30%);
$darkBackground: rgba(255, 255, 255, 0.1);
$emerald: #00a34d;
$green: #4dc942;
$greenDark: desaturate($green, 30%);
$grey: #bdbdc0;
$greyCloudy: #e3e3e7;
$greyDark: #707070;
$greyDark2: #767678;
$greyGraphiteLight: #717173;
$greyLight: #c8c8c8;
$greyLighter: #f5f5f5;
$greyLighter2: #f6f6f9;
$greyPink: #cc71cc;
$greyStone: #aaa;
$marigold: #fc0;
$orange: #ffa800;
$pink: #ff8dff;
$pinkRed: #ff005f;
$purple: #8850ff;
$rose: #ff005f;
$white: #fff;

/* THEME */
$backgroundColor: $greyLighter;
$borderColor: $grey;
$borderRadius: 4px;
$borderRadius--medium: 12px;
$borderRadius--large: 16px;
$borderRadius--x-large: 24px;
$boxShadow: 0 8px 16px rgba(118, 118, 120, 0.2);
$primary: $blue;
$primaryDark: darken($primary, 10%);
$backgroundBlackWithCard: linear-gradient(180deg, $black 50%, $white 50%);
$font--small: 1.4rem;

/* BREAKPOINTS */
$breakpoint-mobile: 970px;
$breakpoint-tablet: 1024px;
$breakpoint-desktop: 1200px;
$breakpoint-mobile-height: 550px;

/* FONT WEIGHTS */
$semiBold: 600;
$bold: 700;
$extraBold: 800;

/* OTHER */

$max-width-home: 1160px;
$max-width-main: 630px;
$max-width-main-large: 904px;

$width-panel: 30.4rem;
$header-height: 7.8rem;

$x-small-spacing: 8px;
$small-spacing: 16px;
$base-spacing: 24px;
$large-spacing: 36px;
$x-large-spacing: 74px;

$condition: $green;
$mission: $pink;
$prestige: $purple;
$social: $blue;
$societal: $orange;

/* stylelint-disable */
:export {
    black: $black;
    bordercolor: $borderColor;
}

/* stylelint-enable */
