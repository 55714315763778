@import 'styles/_variables.scss';

.cards-page {
    &__container {
        align-items: center;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
    }

    &__padded-wrapper {
        opacity: 1;
        transition: all 0.5s;
        width: 100%;
    }

    &__closed-card {
        margin: auto;
        max-width: 514px;

        .card-base__content {
            min-height: 305px;
        }
    }

    &__fragment {
        height: 100%;
        min-height: 3.53rem;
        position: relative;
        width: 100%;
    }

    &__mock {
        position: absolute;
        top: 0;
        z-index: -1;
    }
}

.card-fade-out {
    opacity: 0;
    transition: opacity linear 0.3s 0.3s;
}

.progress-bar-container {
    display: block;
    margin: $large-spacing auto 0;
    width: 28rem;

    @media screen and (max-width: $breakpoint-mobile) {
        width: 19rem;
    }
}
