@import 'styles/_variables.scss';

.form-category-selection__container {
    .button--fixed {
        margin-bottom: 0;

        @media screen and (min-width: $breakpoint-mobile) {
            padding-bottom: 2.4rem;
        }
    }

    .button--block {
        font-size: 1.4rem;
    }

    a.center {
        padding: 1.5rem 1rem 0 1rem;
    }
}
