@import 'styles/_variables';

.description-break {
    &__image {
        margin: 5.6rem 0 0 -2.4rem;
    }

    &__title {
        font-size: 1.8rem;
        font-weight: $extraBold;
        line-height: 2.6rem;
    }
}
