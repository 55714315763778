@import 'styles/_variables';

.card-base__wrapper {
    background-color: $primary;
    border-radius: $borderRadius--large;

    .card-base__content {
        text-align: left;
        padding: 1.6rem;
        position: relative;

        @media screen and (min-width: $breakpoint-mobile) {
            padding: 3.6rem;
        }
    }
}
