@import "styles/_variables.scss";

.job-tasks__main {
    max-width: unset !important;
    text-align: center;

    @media screen and (max-width: $breakpoint-mobile) {
        padding-left: 0;
        padding-right: 0;
    }
}

.job-tasks__block {
    margin: auto;
    max-width: $max-width-main-large;
    padding: 0 2.4rem;
}

.main-job-tasks__header {
    border-bottom: 1px solid $greyCloudy;
    margin-bottom: 3.6rem;
    padding-bottom: 3.6rem;

    @media screen and (max-width: $breakpoint-mobile) {
        margin-top: 2.5rem;
    }

    .main-job-tasks__tasks-completed {
        display: block;
        margin-bottom: 0.8rem;
    }

    h1 {
        font-size: 2.6rem;
        font-weight: $extraBold;
        line-height: 3.4rem;
        margin-bottom: 1.6rem;
    }

    .main-job-tasks__progressBar-container {
        margin: 0 auto;
        width: 23rem;
    }
}

.main-job-tasks__back {
    color: $black;
    left: 2rem;
    position: absolute;
    text-decoration: none;
    top: 2rem;

    @media screen and (max-width: $breakpoint-mobile) {
        top: 8.5rem;
    }

    img {
        height: 2.4rem;
        width: 2.6rem;
    }

    span {
        font-weight: bold;
        margin-left: 0.5rem;
    }
}

.main-job-tasks__container {
    text-align: left;

    h4 {
        font-weight: $bold;
        margin-bottom: 3.6rem;
    }

    label {
        align-items: flex-start;
        border-radius: $borderRadius;
        border: solid 1px $greyLight;
        display: flex;
        margin-bottom: 1.5rem;
        padding: 1.5rem;
        width: 100%;

        &:hover {
            cursor: pointer;
        }

        img {
            margin-right: 1.2rem;
            margin-top: 0.2rem;
        }

        input {
            display: none;
        }
    }

    .button {
        display: block;
        margin: 3.6rem auto 0;
        text-align: center;
        width: 100%;

        @media screen and (min-width: $breakpoint-mobile) {
            width: fit-content;
        }
    }
}
