@import 'styles/_variables';

.main-dashboard {
    &__panel {
        &__title {
            font-size: 1.8rem;
            font-weight: $extraBold;
        }

        &__description {
            color: $greyCloudy;
            font-size: 1.4rem;
            margin-bottom: 3.6rem;
        }
    }
}
