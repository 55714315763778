@import 'styles/_variables.scss';

.congrats__main {
    max-width: 51rem;
    text-align: center;

    @media screen and (min-width: $breakpoint-mobile) {
        padding-top: $x-large-spacing;
    }

    h1 {
        font-size: 32px;
        font-weight: $extraBold;
        line-height: 42px;
        margin-bottom: $small-spacing;
    }

    h2 {
        font-size: 16px;
        line-height: 24px;
        max-width: 47rem;
        margin: auto;
    }

    img {
        margin: $large-spacing 0;
    }

    .button {
        @media screen and (min-width: $breakpoint-mobile) {
            margin: auto;
            width: fit-content;
        }
    }
}
