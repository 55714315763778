@import 'styles/_variables';

.videoContainer {
    position: relative;
    overflow: hidden;
    padding-top: 55%;
    margin-top: 5rem;

    .iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
