.roti__choicefield-wrapper {
    display: flex;
    justify-content: space-between;
}

.roti__choicefield-label-wrapper {
    margin-top: 1.2rem;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;

    > span {
        text-align: center;
    }
}
