@import 'styles/_variables';

.card-category {
    background-color: $black;
    border-radius: 1.2rem;
    color: $white;
    display: inline-block;
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 2rem;
    padding: 0.2rem 1.2rem;
    margin-top: 0.5rem;
    word-break: break-word;
}
