@import 'styles/_variables';

.keys-guide__wrapper {
    bottom: env(safe-area-inset-bottom);
    display: flex;
    position: absolute;
    right: 0;
    width: auto;
    z-index: -1;
}

.keys-guide__guideBlock {
    bottom: $large-spacing;
    display: flex;
    flex-direction: column;
    position: relative;
    right: $large-spacing;

    .keys-guide__title {
        color: $greyStone;
        font-size: 1.4rem;
    }

    .keys-guide__key {
        align-content: center;
        display: flex;
        flex-direction: row;
        margin-top: $small-spacing;

        img {
            margin-bottom: 0;
            margin-right: 1rem;
            width: 2.4rem;
        }

        p {
            color: $greyStone;
            font-size: 1.4rem;
            margin: 0;
        }
    }
}
