@import 'styles/_variables';

.detailed-card__wrapper {
    background-color: $primary;
    bottom: 0;
    height: 100%;
    left: 0;
    margin-top: $header-height;
    overflow: auto;
    padding: 2.4rem;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 500;

    @media screen and (min-width: $breakpoint-mobile) {
        margin-top: 0;
        margin-left: 30rem;
    }
}

.detailed-card {
    background: $white;
    max-width: 736px;
    margin: auto;

    &--desktop {
        max-width: $max-width-main-large;
        margin: 0 auto;
    }

    .detailed-card__market-trend {
        display: flex;
        height: 4rem;
        margin: 2rem 0 3rem 0;
        align-items: center;

        p {
            margin: 0 0 0 1.6rem;
            font-size: 1.4rem;
        }
    }
}

.card-job__title {
    font-size: 2.2rem;
    font-weight: $bold;
    line-height: 2.8rem;
    margin-top: 2.4rem;
    overflow-wrap: break-word;
    text-align: left;

    &::first-letter {
        text-transform: capitalize;
    }

    @media screen and (min-width: $breakpoint-mobile) {
        font-size: 3.2rem;
        line-height: 4.2rem;
    }
}
