@import 'styles/_variables';

.job-offer-card {
    display: flex;
    position: relative;
    background-color: $white;
    border-radius: $borderRadius;
    padding: 2rem;
    text-align: left;
    min-width: 36rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    word-wrap: break-word;

    h4 {
        font-weight: $extraBold;
        font-size: 1.8rem;
        line-height: 2.7rem;
        margin-bottom: 0.5rem;
    }

    &__location {
        color: $greyDark2;
        margin-bottom: 1rem;
    }

    &__contract {
        color: $greyDark2;
        font-size: 1.4rem;
    }

    img {
        width: 6.4rem;
        height: 6.4rem;
        margin-right: 2.4rem;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        font-size: 1.6rem;
        min-width: 23.4rem;
        flex-direction: column;

        img {
            margin-right: 0;
            margin-bottom: 2.4rem;
            width: 4.8rem;
            height: 4.8rem;
        }
    }
}
