@import 'styles/_variables';

.card-more-info {
    align-items: center;
    background: none;
    border-radius: $borderRadius;
    border: 2px solid $black;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    margin-top: 1.6rem;
    padding: 0.6rem 1.6rem;

    span {
        font-size: 1.4rem;
        font-weight: $semiBold;
        line-height: 2.2rem;
    }
}
