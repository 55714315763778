@import "styles/_variables";

.container {
    display: flex;
    height: 100vh;
    min-height: 100vh;
    overflow: hidden;

    @media screen and (max-width: $breakpoint-mobile) {
        height: calc(100vh - #{$header-height});
        min-height: unset;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        flex-direction: column;
        padding-top: $header-height;
    }

    &--black {
        background-color: $black;

        &.container--card {
            background: $backgroundBlackWithCard;
        }
    }

    &--blue {
        background-color: $blue;
    }

    &--primary {
        background-color: $primary;
    }

    &--grey {
        background-color: $backgroundColor;
    }
}

.container__panel {
    min-width: $width-panel;
    z-index: 11;

    &--grey {
        background-color: $backgroundColor;
    }
}
