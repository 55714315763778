@import 'styles/_variables';

.static-page {
    text-align: start;

    .preamble {
        background-color: $white;

        @media screen and (min-width: $breakpoint-mobile) {
            padding: 3.6rem 5.6rem;
        }
    }

    .hero {
        background-color: $greyLighter;
        border-radius: $borderRadius;
        padding: 3rem;
    }

    h1 {
        font-size: 4.2rem;
        font-weight: $extraBold;
        margin-bottom: $base-spacing;
    }

    h2 {
        font-size: 2.2rem;
        font-weight: $extraBold;
        margin-bottom: $base-spacing;
        margin-top: 6.4rem;
    }

    section {
        background-color: $greyLighter2;
        margin: $base-spacing 0;
        padding: $base-spacing;
    }

    p {
        margin-bottom: 1rem;
    }

    a {
        color: $black;
        text-decoration: underline;
    }

    li {
        list-style-type: none;
        margin: 1rem 0 1rem 2.5rem;

        &::before {
            content: "\2022";
            font-size: 1.5rem;
            line-height: 1.9rem;
            margin-left: -6mm;
            position: absolute;
        }
    }
}
