@import 'styles/_variables';

.card-title {
    span {
        color: $white;
        font-size: 3.2rem;
        font-weight: $semiBold;
        line-height: 4.2rem;
        word-break: break-word;
    }
}
