@import 'styles/_variables.scss';

.choicefield__wrapper {
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: $breakpoint-tablet) {
        overflow-x: auto;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

.choicefield {
    border-radius: 100px;
    border: 2px solid $grey;
    cursor: pointer;
    margin: 0.8rem 1.6rem 0.8rem 0;
    padding: 0.6rem 1.6rem;
    white-space: nowrap;

    &.rounded {
        background-color: $greyLighter2;
        border: 2px solid transparent;
        display: inline-block;
        font-weight: $bold;
        height: 4.8rem;
        margin: 0;
        padding: 1.1rem 0 0;
        text-align: center;
        width: 4.8rem;

        &.choicefield--active {
            border-color: $primary;
        }

        @media screen and (max-width: $breakpoint-mobile) {
            height: 3.5rem;
            padding-top: 0.5rem;
            width: 3.5rem;
        }
    }

    &:hover,
    &--active {
        border-color: $primary;
    }

    input {
        appearance: none;
        background: transparent;
        border-color: transparent;
        height: 0;
        position: fixed;
        width: 0;
    }

    label {
        color: $black;
        font-size: 1.4rem;

        a {
            color: $black;
        }
    }

    &--error {
        background-color: #fdf4f7;
        border: solid 1px $pinkRed;
        color: $pinkRed;
        padding: 1rem;
    }
}
