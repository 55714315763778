@import "styles/_variables.scss";

.main-step1-transition {
    &__container {
        text-align: center;

        @media screen and (min-width: $breakpoint-mobile) {
            padding-top: $x-large-spacing;
        }

        h1 {
            font-size: 2.6rem;
            font-weight: $extraBold;
            line-height: 3.4rem;
            margin-bottom: 3.6rem;

            @media screen and (min-width: $breakpoint-mobile) {
                font-size: 3.2rem;
            }
        }
    }

    &__label {
        border: solid 1px $greyCloudy;
        border-radius: $borderRadius;
        display: block;
        margin-bottom: 1.5rem;
        padding: 1.5rem;
        text-align: left;
        width: 80%;

        @media screen and (max-width: $breakpoint-mobile) {
            width: 100%;
        }

        &:hover {
            box-shadow: $boxShadow;
            cursor: pointer;
        }
    }

    &__questions {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: 3rem;
    }

    &__input {
        display: none;
    }

    &__input:checked + .main-step1-transition__label {
        background-color: $primary;
    }
}
