@import 'styles/_variables';

.card-skills {
    .list-skills {
        display: flex;
        justify-content: flex-start;
        flex-flow: row wrap;
    }

    .skill {
        border: 1px solid #000;
        box-sizing: border-box;
        border-radius: 10rem;
        padding: 0.5rem 1.2rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 1rem;
    }
}
