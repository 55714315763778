@import 'styles/_variables';

.language-switcher {
    .field {
        margin-bottom: 0;
    }

    &--black {
        .select_container {
            background-color: $black;
            border-color: $greyGraphiteLight;
            color: $white;
        }

        .select {
            &__control {
                background-color: $black;
                color: $white;
                cursor: pointer;

                &--is-focused {
                    border-color: $white !important;
                }
            }

            &__menu {
                background-color: $black;
                border: 1px solid $greyGraphiteLight;
                color: $white;
            }

            &__menu-list {
                background-color: $black;
                color: $white;
            }

            &__option {
                background-color: $black !important;
                color: $white;
                cursor: pointer;
            }

            &__indicator {
                color: $white !important;
            }

            &__single-value {
                color: $white;
            }
        }
    }

    // For HomeHeader and Footer
    &__small {
        color: $black;
        width: auto;
        min-width: 46px;

        .field input {
            padding: 0;
        }

        @media screen and (max-width: $breakpoint-mobile) {
            margin-left: 0;
            margin-top: 1.2rem;
            max-width: 123px;
        }

        @media screen and (min-width: $breakpoint-mobile) {
            .field {
                max-width: 45px;
            }

            .select_container {
                border-color: transparent;
            }

            .select {
                &__control {
                    border: unset;
                    min-height: unset;
                }

                &__value-container {
                    padding: 0;
                }

                &__single-value {
                    margin: 0 4px 0 0;
                }

                &__indicator-separator {
                    display: none;
                }

                &__indicator {
                    padding: 0;

                    svg {
                        height: 15px;
                        width: 15px;
                    }
                }
            }
        }
    }
}
