@import "styles/_variables";

.share-img {
    background-color: $black;
    overflow: hidden;
    width: 500px;
    height: 250px;

    &__illustration {
        width: 25.5rem;
        bottom: 0;
        right: 0;
        position: absolute;
    }

    &__intro {
        margin-left: $large-spacing;
        margin-top: 5rem;

        .button {
            margin-bottom: 0;
            font-size: 1.2rem;
            padding: 1rem 1.2rem;
        }
    }

    &__title {
        color: $white;
        font-size: 2.3rem;
        font-weight: $extraBold;
        line-height: 2.9rem;
        margin-bottom: $small-spacing;
        max-width: 19.8rem;
    }
}
