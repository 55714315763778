@import 'styles/_variables';

.not-found-button {
    background-color: $black;
    border-radius: $borderRadius;
    border: 0;
    color: $white;
    font-size: 1.6rem;
    font-weight: 400;
    height: 5rem;
    padding: 1.2rem 2.4rem;
    text-decoration: none;

    @media screen and (max-width: $breakpoint-mobile) {
        text-align: center;
    }

    &:hover {
        background-color: lighten($black, 20%);
    }
}
