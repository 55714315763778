@import 'styles/_variables';
@import 'styles/_fonts';
@import 'styles/_form';
@import 'styles/_reset';
@import 'styles/_static';
@import 'styles/_texts';
@import 'styles/_toast';
@import 'styles/_utils';

*,
*::before,
*::after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-size: calc(1em * 0.625);
    height: -webkit-fill-available;
}

body {
    color: $black;
    font-family: 'Mulish', sans-serif;
    font-size: 1.6rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4;
    margin: 0;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    overflow: hidden;
    position: fixed;
    width: 100%;

    @media screen and (max-width: $breakpoint-mobile) {
        overflow: visible;
        position: relative;
        min-height: unset;
    }
}

img {
    max-width: 100%;
}

hr {
    border: none;
    border-top: 1px solid $borderColor;
    margin: 1.6rem 0;
}

#app {
    display: flex;
    flex-direction: column;
    height: 100vh;

    @media screen and (max-width: $breakpoint-mobile) {
        height: 100%;
    }
}
