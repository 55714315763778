@import "styles/_variables";

.header-menu__wrapper-menu {
    background: $black;
    border-radius: 0 0 $borderRadius $borderRadius;
    margin: auto;
    max-width: $max-width-home;
    padding: 2.4rem 2rem;
    width: 100%;

    @media screen and (min-width: $breakpoint-mobile) {
        box-sizing: content-box;
        padding: 2.4rem;
    }
}

.header-menu__inner {
    align-items: center;
    border-radius: 0 0 $borderRadius $borderRadius;
    display: flex;
    justify-content: space-between;
    z-index: 100;
}

button.header-menu__login-button {
    align-items: center;
    background: none;
    border: none;
    color: $white;
    display: flex;
    min-height: 3rem;
    padding: 0;

    span {
        display: none;
    }

    img {
        margin: 0 !important;
    }

    &:hover {
        background: none;
        text-decoration: underline;
    }

    @media screen and (min-width: $breakpoint-mobile) {
        span {
            display: block;
            margin-left: 1.2rem;
        }
    }
}
