@import 'styles/_variables';

.selectable-job-card__container {
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: $white;
    border-radius: $borderRadius;
    border: 1px solid $grey;
    min-height: 13.6rem;
    padding: 1.5rem;
    text-align: left;

    input {
        display: none;
    }

    h4 {
        font-weight: $extraBold;
        font-size: 1.8rem;
        line-height: 2.7rem;
        margin-bottom: 0.5rem;
    }

    .selectable-job-card__description {
        margin-bottom: 1.6rem;
    }

    &:hover {
        box-shadow: $boxShadow;
        cursor: pointer;
    }

    &--selected {
        background-color: $primary;
    }

    &--disabled {
        color: $grey;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        font-size: 1.6rem;
    }

    .selectable-job-card__link {
        min-height: 3.6rem;

        & button {
            margin-top: auto;
            font-weight: $bold;
            text-decoration: underline;
        }
    }

    .selectable-job-card__favorite {
        display: flex;
        justify-content: flex-end;

        & img {
            width: 3.6rem;
        }
    }
}
