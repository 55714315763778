@import 'styles/_variables';

.card-job-mock__card-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    width: 100%;
}

.card-job-mock__card {
    flex: 1;
}

.card-job-mock__title {
    margin-top: 2.4rem;
}

.card-job-mock__divider {
    margin: 1.6rem 0;
    border-top-color: $black;
}

.card-job-mock__info-container {
    margin-top: 1.6rem;
    text-align: left;

    button {
        cursor: pointer;
    }
}
