@import 'styles/_variables';

.end-form__last-thing {
    margin: 3.6rem 0;

    @media screen and (max-width: $breakpoint-mobile) {
        margin: 2.4rem 0;
        padding-left: 2rem;
    }
}

.end-form__container {
    background-color: $white;
    border-radius: $borderRadius--large;
    color: $black;
    margin-bottom: 8.8rem;
    padding: 4rem 3.6rem;
    text-align: left;
    width: 100%;

    @media screen and (max-width: $breakpoint-mobile) {
        border-radius: 0;
        margin-bottom: 0;
        padding: 2.4rem 2rem;
    }

    h1 {
        border-bottom: 1px solid $greyCloudy;
        font-size: 3.2rem;
        font-weight: $extraBold;
        line-height: 4.2rem;
        margin-bottom: 3.6rem;
        padding-bottom: 3.6rem;

        @media screen and (max-width: $breakpoint-mobile) {
            border-bottom: none;
            border-radius: 0;
            font-size: 2.2rem;
            padding-bottom: 0;
        }
    }

    h2 {
        font-size: 2.2rem;
        font-weight: $extraBold;
        line-height: 2.8rem;
        margin-bottom: 3.6rem;
    }

    h3 {
        font-size: 1.8rem;
        font-weight: $bold;
        line-height: 2.6rem;
        margin-bottom: 4.8rem;
    }
}

.end-form__roti-container {
    margin-bottom: 3.6rem;
    margin-top: 6.4rem;

    .label {
        font-weight: 400;
        margin-bottom: 1.5rem;
    }
}

.slider-form {
    bottom: 0;
    height: 2rem;
    margin-top: 4.5rem;
    position: absolute;
    width: 100%;
}

.slider-form__thumb {
    background-color: $primary;
    border-radius: 50px;
    cursor: pointer;
    height: 2rem;
    outline: none;
    text-align: center;
    top: 0.5rem;
    width: 2rem;
}

.slider-form__value {
    background-color: $white;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    font-size: 1.2rem;
    left: 8px;
    line-height: 25px;
    position: relative;
    top: -40px;
    width: 60px;

    &::before,
    &::after {
        content: "";
        position: absolute;
    }

    &::before {
        background-color: white;
        border-left: 1px solid #c4c4c4;
        bottom: -6px;
        height: 10px;
        left: -1px;
        width: 6px;
        z-index: 99;
    }

    &::after {
        border-right: 1px solid #c4c4c4;
        bottom: -4px;
        height: 8px;
        left: -6px;
        width: 10px;
        z-index: 100;
        -moz-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
}

.slider-form__track {
    background-color: $greyCloudy;
    border-radius: 20px;
    height: 0.8rem;
    top: 1.2rem;

    &-0 {
        background-color: $primary;
    }
}

.end-form__submit-button {
    display: block;
    margin: 0 auto;
}
