@import 'styles/_variables.scss';
@import 'styles/_animations.scss';

.mock {
    background: #00a7b3;
    background-image: linear-gradient(to right, #00a7b3 0%, #00becc 20%, #00a7b3 40%, #00a7b3 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    border-radius: 16px;
    display: block;
    height: 4.2rem;
    width: 15rem;
    animation: 1s linear 0s infinite normal forwards running placeholderShimmer;
    -webkit-animation: 1s linear 0s infinite normal forwards running placeholdershimmer;
}
