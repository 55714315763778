@import "styles/_variables";

.endForm-panel {
    &__title {
        font-size: 1.8rem;
        font-weight: $extraBold;
        line-height: 2.6rem;
    }

    &__description {
        color: $greyCloudy;
        font-size: 1.4rem;
        line-height: 2.2rem;
    }
}
