@import 'styles/_variables';

.card-icon-line {
    font-size: 3.2rem;
    font-weight: $semiBold;
    line-height: 4.2rem;

    .title-container {
        align-items: center;
        display: flex;
        flex-direction: row;
    }

    img {
        height: 2rem;
        margin-right: 1.7rem;
        width: 2rem;
    }

    span {
        color: $white;
    }

    span.bold {
        font-weight: $bold;
    }

    p {
        color: $white;
        display: block;
        margin-left: 3.7rem; /* 20px of img width + 17px of img margin-right */
        margin-top: 0.4rem;
    }
}
