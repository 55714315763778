@import "styles/_variables.scss";

.go-further-card__container {
    padding: 0 2.4rem;
    display: flex;
    background-color: $greyLighter2;
    border-radius: $borderRadius;
    min-height: 25rem;

    @media screen and (max-width: $breakpoint-mobile) {
        flex-direction: column;
    }
}

.go-further-card__image {
    margin-top: auto;
    margin-right: 2.6rem;
    height: 21rem;

    @media screen and (max-width: $breakpoint-mobile) {
        flex-direction: column;
        margin: 2.4rem auto 0;
    }
}

.go-further-card__content {
    margin: auto 0;
    text-align: left;

    h2 {
        font-weight: $extraBold;
        font-size: 2.2rem;
        line-height: 2.8rem;
        margin-bottom: 0.8rem;

        @media screen and (max-width: $breakpoint-mobile) {
            margin-top: 1.6rem;
        }
    }

    span {
        display: block;
        margin-bottom: 1.6rem;
    }

    li {
        margin-left: 2rem;
        list-style-type: disc;
    }
}

.go-further-card__link {
    border-radius: $borderRadius;
    font-size: 1.4rem;
    text-decoration: none;
    color: $white;
    background-color: $black;
    padding: 0.7rem 1.6rem;

    @media screen and (max-width: $breakpoint-mobile) {
        text-align: center;
        display: block;
        margin-bottom: 2.6rem;
        font-size: 1.6rem;
        font-weight: 600;
        height: 5rem;
        padding: 1.2rem 3.2rem;
    }
}

button.shareButton {
    margin-top: 1rem;
    color: $black;

    @media screen and (max-width: $breakpoint-mobile) {
        margin-bottom: 3rem;
    }

    img {
        margin-bottom: 0.3rem;
    }
}
