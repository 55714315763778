@import 'styles/_variables.scss';

.selectable-field {
    &__label {
        align-items: center;
        border: solid 1px $greyLight;
        border-radius: $borderRadius;
        display: flex;
        margin-bottom: 1.5rem;
        min-height: 5.5rem;
        padding: 0 $small-spacing;
        width: 100%;

        &:hover {
            cursor: pointer;
        }

        img {
            margin-right: $x-small-spacing;
        }
    }

    &__label-without-border {
        border: none;
        padding: 0 5px;
    }

    &__input {
        display: none;
    }
}

.selectable-field__input:checked + .selectable-field__label {
    border: solid 2px $primary;
}

.selectable-field__input:checked + .selectable-field__label-without-border {
    border: none;
}

.selectable-field__input:disabled + .selectable-field__label {
    cursor: not-allowed;
}
