@import 'styles/_variables.scss';

.menu {
    display: flex;
    flex-direction: column;
    height: calc(100% - #{$header-height});
    padding: 4rem $base-spacing 1.6rem;

    @media screen and (min-width: $breakpoint-mobile) {
        font-size: 0.9em;
        margin-top: 0;
    }

    &__title {
        color: $white;
        font-size: 1.8rem;
        font-weight: $extraBold;
        line-height: 1.3;
        margin-bottom: $base-spacing;
    }

    .language-switcher {
        color: $black;
        margin-bottom: $base-spacing;
    }

    &__inner {
        border-top: 1px solid $greyGraphiteLight;
        color: $white;
        width: 100%;
    }

    &__link {
        display: flex;
        margin-top: $base-spacing;
        width: 100%;

        &--disabled {
            opacity: 0.2;
        }

        > :first-child {
            align-items: center;
            color: inherit;
            cursor: pointer;
            display: flex;
            font-size: 1.4rem;
            font-weight: normal;
            line-height: 2.2rem;
            text-align: left;
            text-decoration: none;
            width: 100%;

            img {
                margin-right: 0.8rem;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
}
