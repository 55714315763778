@import 'styles/_variables';

.field__input {
    width: 100%;
    padding: 1.2rem 1.6rem;
    font-family: 'Mulish', sans-serif;
    font-size: 1.6rem;
    border-radius: $borderRadius;
    border: solid 1px $grey;

    &::placeholder {
        color: $grey;
    }
}
