@import 'styles/_variables';

.main__wrapper {
    height: 100%;
    overflow-x: auto;
    position: relative;
    width: 100%;
    z-index: 10;

    @media screen and (max-width: $breakpoint-mobile) {
        min-height: unset;
    }
}

.main {
    margin: auto;
    max-width: $max-width-main;
    padding: $large-spacing 20px;

    @media screen and (min-width: $breakpoint-mobile) {
        text-align: center;
    }

    &--large {
        max-width: $max-width-main-large;
    }

    &--hidden {
        display: none;
    }

    .container--black & {
        background-color: $black;
        color: $white;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        margin-top: $large-spacing;
        width: 100%;

        & > :nth-child(2) {
            margin-top: $small-spacing;
        }

        @media screen and (min-width: $breakpoint-mobile) {
            flex-direction: row;
            justify-content: center;

            & > :nth-child(2) {
                margin-left: $small-spacing;
                margin-top: 0;
            }
        }
    }
}
