@import 'styles/_variables';

.panel__backdrop {
    overflow: auto;
}

.panel {
    background-color: $black;
    color: white;
    display: flex;
    flex-direction: column;
    height: 100vh;
    left: 0;
    overflow: auto;
    position: absolute;
    top: 0;
    width: $width-panel;

    @media screen and (max-width: $breakpoint-mobile) {
        height: auto;
        min-width: auto;
        width: 100%;
    }
}

.panel--open {
    @media screen and (max-width: $breakpoint-mobile) {
        height: 100%;
    }
}

.panel__header {
    background-color: $black;
    display: flex;
    justify-content: space-between;
}

.panel__title {
    font-size: 2.2rem;
}

.panel__inner {
    border-bottom: 1px solid $greyDark2;
    height: 100%;
    margin: 40px $base-spacing 0;
    padding-bottom: 20px;
}
