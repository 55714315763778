@import "styles/_variables.scss";

.account {
    &__container {
        @media screen and (min-width: $breakpoint-mobile) {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
        }
    }

    &__title {
        margin-bottom: $small-spacing;
    }
}
